import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import DropComp from "./LatestData/DropComp";
import "../Styles/Navbar.css";

const HeaderComp = () => {
  const [openData, setOpenData] = useState(null);
  const dropdownRef = useRef(null);
  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenData(null);
    }
  };


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const toggleData = (data) => {
    setOpenData((prevData) => (prevData === data ? null : data));
  };

  return (
    <>
      <div className="main-header">
        <ul className="bothsidenav borderB">
          {["Latest", "Leadership", "Features"].map((item) => (
            <li key={item} className="hover-underline-animation hoverHead" onClick={() => toggleData(item)}>
              {item} <span className="smalltraingle"> &#x25BC;</span>
              {!isLargeScreen && openData === item && (
                <div className="mobile-dropdown open" ref={dropdownRef}>
                  <DropComp
                    section={item}
                    maxItems={1}
                    className="mobile-screen"
                    handleSectionClick={toggleData}
                    hideTextAndSeeMore={true} 
                  />
                </div>
              )}
            </li>
          ))}
          <li className="hover-underline-animation hoverHead">
            <a className={`hoverHead ${isLargeScreen ? "a-tag" : "text-white text-decoration-none"}`} href="/contact-us">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      {isLargeScreen && openData && (
        <div ref={dropdownRef} className="large-screen">
          <DropComp
            section={openData}
            maxItems={3}
            handleSectionClick={toggleData}
            hideTextAndSeeMore={false} 
          />
        </div>
      )}
    </>
  );
};

export default React.memo(HeaderComp);
